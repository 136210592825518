import { FC } from "react";
import { formatNumber } from "../../helpers/formatNumber";
import classNames from "classnames";
import s from "./Advertisement.module.scss";

interface IAdvertisementProps {
    className?: string;
    name: string;
    newPrice: number;
    oldPrice: number;
    productImageUrl: string;
    dateEnd: Date;
}

const Advertisement: FC<IAdvertisementProps> = ({
    className = "",
    name,
    newPrice,
    oldPrice,
    productImageUrl,
    dateEnd,
}) => {
    return (
        <div className={classNames(s.advertisement, className)}>
            <div className={s.stock}>Акция</div>
            <div className={s.header}>
                <span className={s.new_price}>{formatNumber(newPrice)}</span>
                <span className={s.old_price}>{formatNumber(oldPrice)}</span>
            </div>
            <div className={s.main_content}>
                <div>
                    <img
                        className={s.image}
                        src={productImageUrl}
                    />
                </div>
                <span className={s.name}>{name}</span>
            </div>
            <footer className={s.footer}>
                <span>Акция действует до</span>
                <span className={s.date_end}>{dateEnd.toLocaleDateString("ru")}</span>
            </footer>
        </div>
    );
};

export default Advertisement;
