import { all, call, takeLatest } from "redux-saga/effects";
import { getFilterRequest, getProductsRequest } from "./shopReducer";
import { getFilterWorker, getProductsWorker } from "./workers";

function* getFilterWatcher() {
    yield takeLatest(getFilterRequest, getFilterWorker);
}

function* getProductsWatcher() {
    yield takeLatest(getProductsRequest, getProductsWorker);
}

export function* shopWatcher() {
    yield all([call(getFilterWatcher), call(getProductsWatcher)]);
}
