import { Navigate, Route, Routes } from "react-router-dom";
import { privateRoutes, routers, routeUrls } from "./routes/routers";
import MainLayout from "./layout";
import PrivateRoute from "./routes/PrivateRoute";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { FC, useEffect } from "react";
import { checkAuthRequest } from "./redux/reducers/authenticationReducer/authenticationReducer";
import { getProfileRequest } from "./redux/reducers/accountReducer/accountReducer";

const App: FC = () => {
    const { isAuth } = useAppSelector(state => state.authenticationReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(checkAuthRequest());
    }, []);

    useEffect(() => {
        isAuth && dispatch(getProfileRequest());
    }, [isAuth]);

    return (
        <Routes>
            <Route element={<MainLayout />}>
                {routers.map(r => {
                    return (
                        <Route
                            key={r.path}
                            path={r.path}
                            element={r.element}
                        />
                    );
                })}
                <Route
                    path="*"
                    element={<Navigate to={routeUrls.hydroCycles} />}
                />
            </Route>
            <Route
                element={
                    <PrivateRoute>
                        <MainLayout />
                    </PrivateRoute>
                }
            >
                {privateRoutes.map(r => (
                    <Route
                        key={r.path}
                        path={r.path}
                        element={r.element}
                    />
                ))}
            </Route>
        </Routes>
    );
};

export default App;
