import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationState } from "./types/authenticationState";
import { RegistrationFailure, RegistrationRequest, RegistrationSuccess } from "./types/actionTypes/registrationTypes";
import { LoginFailure, LoginRequest, LoginSuccess } from "./types/actionTypes/loginTypes";
import { LogoutFailure, LogoutRequest, LogoutSuccess } from "./types/actionTypes/logoutTypes";
import { CheckAuthFailure, CheckAuthSuccess } from "./types/actionTypes/checkAuthTypes";

const initialState: AuthenticationState = {
    loginPending: false,
    logoutPending: false,
    registrationPending: false,
    checkAuthPending: false,
    isAuth: false,
    registrationError: null,
    loginError: null,
    error: null,
};

export const AuthenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        registrationRequest(state, action: RegistrationRequest) {
            state.registrationPending = true;
        },
        registrationSuccess(state, action: RegistrationSuccess) {
            state.registrationPending = false;
            state.isAuth = true;
        },
        registrationFailure(state, action: RegistrationFailure) {
            state.registrationPending = false;
            state.registrationError = action.payload;
        },

        loginRequest(state, action: LoginRequest) {
            state.loginPending = true;
        },
        loginSuccess(state, action: LoginSuccess) {
            state.loginPending = false;
            state.isAuth = true;
        },
        loginFailure(state, action: LoginFailure) {
            state.loginPending = false;
            state.loginError = action.payload;
        },

        checkAuthRequest(state) {
            state.checkAuthPending = true;
            state.error = null;
        },
        checkAuthSuccess(state, action: CheckAuthSuccess) {
            state.checkAuthPending = false;
            state.isAuth = action.payload;
        },
        checkAuthFailure(state, action: CheckAuthFailure) {
            state.checkAuthPending = false;
            state.error = action.payload;
        },

        logoutRequest(state, action: LogoutRequest) {
            state.logoutPending = true;
            state.error = null;
        },
        logoutSuccess(state, action: LogoutSuccess) {
            state.logoutPending = false;
            state.isAuth = false;
        },
        logoutFailure(state, action: LogoutFailure) {
            state.logoutPending = false;
            state.error = action.payload;
        },
    },
});

export const {
    registrationRequest,
    registrationSuccess,
    registrationFailure,
    loginRequest,
    loginSuccess,
    loginFailure,
    checkAuthRequest,
    checkAuthSuccess,
    checkAuthFailure,
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
