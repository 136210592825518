import { FC } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { privateRoutes, routers } from "../../routes/routers";
import s from "./Breadcumbs.module.scss";

const Breadcrumbs: FC = () => {
    const location = useLocation();
    const currentRoute = routers.concat(privateRoutes).find(r => location.pathname.includes(r.path));

    return (
        <Breadcrumb className={s.breadcrumbs}>
            <BreadcrumbItem>
                <Link
                    className={s.link}
                    to={"/main"}
                >
                    Главная
                </Link>
            </BreadcrumbItem>
            {currentRoute && (
                <BreadcrumbItem>
                    <Link
                        className={s.link}
                        to={currentRoute.path}
                    >
                        {currentRoute.title}
                    </Link>
                </BreadcrumbItem>
            )}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
