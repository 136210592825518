import React, { FC, useCallback } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import classNames from "classnames";
import s from "./PaginationMain.module.scss";

interface IPaginationMainProps {
    currentPage: number;
    pageCount: number;
    onChangePage: (newPage: number) => void;
}

const PaginationMain: FC<IPaginationMainProps> = ({ currentPage, pageCount, onChangePage }) => {
    const changePageHandler = useCallback((e: React.ChangeEvent<unknown>, newPage: number) => {
        onChangePage(newPage);
    }, []);

    return (
        <Pagination
            className={s.pagination}
            variant={"outlined"}
            shape={"rounded"}
            renderItem={item => (
                <PaginationItem
                    {...item}
                    className={classNames(s.pagination__item, {
                        [s.selected_page]: item.page === currentPage,
                    })}
                />
            )}
            defaultPage={1}
            count={pageCount}
            page={currentPage}
            hideNextButton={true}
            hidePrevButton={true}
            onChange={changePageHandler}
        />
    );
};

export default PaginationMain;
