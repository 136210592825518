import { FC } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as HeartIcon } from "../../assets/svg/heart.svg";
import { ReactComponent as CartIcon } from "../../assets/svg/cart.svg";
import { ReactComponent as HouseIcon } from "../../assets/svg/house.svg";
import { ReactComponent as PercentIcon } from "../../assets/svg/percent.svg";
import { ReactComponent as BoxIcon } from "../../assets/svg/box.svg";

import { Link, useLocation } from "react-router-dom";
import { routers } from "../../routes/routers";
import classNames from "classnames";
import s from "./SidebarMenu.module.scss";
import { menuItems } from "./MenuItems";

interface ISidebarMenuProps {
    isOpen: boolean;
    toggle: () => void;
}

const SidebarMenu: FC<ISidebarMenuProps> = ({ isOpen, toggle }) => {
    const location = useLocation();

    return (
        <Offcanvas
            className={s.sidebar}
            isOpen={isOpen}
            toggle={toggle}
        >
            <OffcanvasHeader toggle={toggle}>Меню</OffcanvasHeader>
            <OffcanvasBody className={s.sidebar__body}>
                <nav className={s.sidebar__list}>
                    {menuItems.map((item, i) => (
                        <a
                            key={i}
                            className={classNames(s.sidebar__item, s.with_icon)}
                        >
                            {item.icon}
                            {item.title}
                        </a>
                    ))}
                    {routers.map((r, i) => (
                        <Link
                            key={i}
                            className={classNames(s.sidebar__item, {
                                [s.active]: location.pathname.includes(r.path),
                            })}
                            to={r.path}
                        >
                            {r.title}
                        </Link>
                    ))}
                </nav>
                <div className={s.location}>Москва, ул. Науки 25</div>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default SidebarMenu;
