import { FC } from "react";
import classNames from "classnames";
import s from "./Checkbox.module.scss";

interface ICheckboxBlockProps {
    className?: string;
    text: string;
    checked: boolean;
    onClick: () => void;
}

const CheckboxBlock: FC<ICheckboxBlockProps> = ({ className = "", text, checked, onClick }) => {
    return (
        <div
            className={classNames(s.container, { [s.checked]: checked }, className)}
            onClick={onClick}
        >
            {text}
        </div>
    );
};

export default CheckboxBlock;
