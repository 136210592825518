import { FC, ReactElement } from "react";
import { useAppSelector } from "../redux/hooks";
import { Navigate } from "react-router-dom";

interface IPrivateRouteProps {
    children: ReactElement;
}
const PrivateRoute: FC<IPrivateRouteProps> = ({ children }) => {
    const { isAuth } = useAppSelector(state => state.authenticationReducer);

    if (!isAuth || !localStorage.getItem("@ACCESS_TOKEN")) {
        return <Navigate to={`/login`} />;
    }

    return children;
};

export default PrivateRoute;
