import { createContext, FC, ReactNode, useCallback, useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Main } from "./Main";
import { Container } from "reactstrap";
import { LoginPage, RegistrationPage } from "../pages/AuthorizationPage";

interface IAuthModalContext {
    loginIsOpen: boolean;
    registrationIsOpen: boolean;
    toggleLoginModalOpenHandler: (() => void) | null;
    toggleRegistrationModalOpenHandler: (() => void) | null;
}

export const AuthModalContext = createContext<IAuthModalContext>({
    loginIsOpen: false,
    registrationIsOpen: false,
    toggleLoginModalOpenHandler: null,
    toggleRegistrationModalOpenHandler: null,
});

interface IAuthModalProviderProps {
    children: ReactNode;
}
const AuthModalProvider: FC<IAuthModalProviderProps> = ({ children }) => {
    const [loginIsOpen, setLoginIsOpen] = useState(false);
    const [registrationIsOpen, setRegistrationIsOpen] = useState(false);

    const toggleLoginModalOpenHandler = useCallback(() => setLoginIsOpen(prev => !prev), []);

    const toggleRegistrationModalOpenHandler = useCallback(() => setRegistrationIsOpen(prev => !prev), []);

    return (
        <AuthModalContext.Provider
            value={{ loginIsOpen, registrationIsOpen, toggleLoginModalOpenHandler, toggleRegistrationModalOpenHandler }}
        >
            {children}
        </AuthModalContext.Provider>
    );
};

const MainLayout: FC = () => {
    return (
        <>
            <AuthModalProvider>
                <Container fluid={false}>
                    <Header />
                    <Main />
                </Container>
                <Footer />
                <RegistrationPage />
                <LoginPage />
            </AuthModalProvider>
        </>
    );
};

export default MainLayout;
