import { HydrocyclesPage } from "../pages/HydrocyclesPage";
import { AccountPage } from "../pages/AccountPage";
import { RouteData } from "../types/routeData";

export enum routeUrls {
    account = "/account",
    quadBikes = "/quadbikes",
    motoBoats = "/motoboats",
    hydroCycles = "/hydrocycles",
    boats = "/boats",
    avts = "/avts",
    snowBikes = "/snowbikes",
    engines = "/engines",
    repairs = "/repairs",
}

export const privateRoutes: RouteData[] = [
    { title: "Личный кабинет", path: routeUrls.account, element: <AccountPage /> },
];

export const routers: RouteData[] = [
    {
        title: "Квадроциклы",
        path: routeUrls.quadBikes,
        element: <HydrocyclesPage />,
    },
    {
        title: "Катера",
        path: routeUrls.motoBoats,
        element: <HydrocyclesPage />,
    },
    {
        title: "Гидроциклы",
        path: routeUrls.hydroCycles,
        element: <HydrocyclesPage />,
    },
    {
        title: "Лодки",
        path: routeUrls.boats,
        element: <HydrocyclesPage />,
    },
    {
        title: "Вездеходы",
        path: routeUrls.avts,
        element: <HydrocyclesPage />,
    },
    {
        title: "Снегоходы",
        path: routeUrls.snowBikes,
        element: <HydrocyclesPage />,
    },
    {
        title: "Двигатели",
        path: routeUrls.engines,
        element: <HydrocyclesPage />,
    },
    {
        title: "Запчасти",
        path: routeUrls.repairs,
        element: <HydrocyclesPage />,
    },
];
