import { all, call, takeLatest } from "redux-saga/effects";
import { checkAuthRequest, loginRequest, logoutRequest, registrationRequest } from "./authenticationReducer";
import { checkAuthWorker, loginWorker, logoutWorker, registrationWorker } from "./workers";

function* registrationWatcher() {
    yield takeLatest(registrationRequest, registrationWorker);
}

function* loginWatcher() {
    yield takeLatest(loginRequest, loginWorker);
}

function* checkAuthWatcher() {
    yield takeLatest(checkAuthRequest, checkAuthWorker);
}

function* logoutWatcher() {
    yield takeLatest(logoutRequest, logoutWorker);
}

export function* authenticationWatcher() {
    yield all([call(registrationWatcher), call(loginWatcher), call(checkAuthWatcher), call(logoutWatcher)]);
}
