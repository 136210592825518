import { FC, ReactNode } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface IModalMainProps {
    isOpen: boolean;
    toggle: () => void;
    title?: string;
    bodyClassName?: string;
    children: ReactNode;
}
const ModalMain: FC<IModalMainProps> = ({ isOpen, toggle, title, bodyClassName = "", children }) => {
    return (
        <Modal
            centered={true}
            isOpen={isOpen}
            toggle={toggle}
            unmountOnClose={true}
        >
            {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
            <ModalBody className={bodyClassName}>{children}</ModalBody>
        </Modal>
    );
};

export default ModalMain;
