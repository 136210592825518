import baseApi from "./baseApi";
import { FilterSearch } from "../redux/reducers/shopReducer/types/filterSearch";
import { AxiosResponse } from "axios";
import { ProductResponse } from "../redux/reducers/shopReducer/types/productResponse";

export const shopApi = {
    getFilter: (): Promise<AxiosResponse<{ pageCount: number; products: ProductResponse[] }>> => {
        return baseApi.get("/api/products/getFilter");
    },

    getProducts: (filter: FilterSearch) => {
        const { Actions, ...rest } = filter;
        let resultStr = "?" + Actions.map(e => `Actions=${e}`).join("&");
        let url = "/api/products";
        if (Actions.length) url = url + resultStr;
        return baseApi.get(url, {
            params: { ...rest },
        });
    },
} as const;
