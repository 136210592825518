import { FC, useCallback } from "react";
import { ProductCard } from "../ProductCard";
import { Row, Spinner } from "reactstrap";
import { PaginationMain } from "../PaginationMain";
import { Product } from "../../redux/reducers/shopReducer/types/product";
import s from "./ProductsGrid.module.scss";

interface IProductsGridProps {
    isLoading: boolean;
    products: Product[];
    searchText: string;
    currentPage?: number;
    pageCount?: number;
    onToggleFavorite: (id: string) => void;
    onChangePage?: (pageNumber: number) => void;
}

const ProductsGrid: FC<IProductsGridProps> = ({
    isLoading,
    products,
    searchText,
    currentPage = 0,
    pageCount = 0,
    onToggleFavorite,
    onChangePage,
}) => {
    const changePageHandler = useCallback((newPage: number) => onChangePage?.(newPage), []);

    return isLoading ? (
        <div className={s.spinner__container}>
            <Spinner
                className={s.spinner}
                color={"primary"}
            />
        </div>
    ) : (
        <div>
            <div className={s.products}>
                {products.filter(p => p.name.toLowerCase().includes(searchText.toLowerCase())).length === 0 ? (
                    <h1>Товаров нет</h1>
                ) : (
                    products
                        .filter(p => p.name.toLowerCase().includes(searchText.toLowerCase()))
                        .map(p => (
                            <ProductCard
                                key={p.id}
                                product={{ ...p }}
                                onToggleFavorite={onToggleFavorite}
                            />
                        ))
                )}
            </div>
            {pageCount > 1 && (
                <Row>
                    <PaginationMain
                        currentPage={currentPage}
                        pageCount={pageCount}
                        onChangePage={changePageHandler}
                    />
                </Row>
            )}
        </div>
    );
};

export default ProductsGrid;
