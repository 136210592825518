import { FC, useCallback } from "react";
import { formatNumber } from "../../helpers/formatNumber";
import { ReactComponent as HeartIcon } from "../../assets/svg/heart.svg";
import { ReactComponent as FilledHeartIcon } from "../../assets/svg/heart_filled.svg";
import { ReactComponent as CartIcon } from "../../assets/svg/cart.svg";
import classNames from "classnames";
import { Product } from "../../redux/reducers/shopReducer/types/product";
import { Checkbox } from "@mui/material";
import s from "./ProductCard.module.scss";

interface IProductCardProps {
    className?: string;
    product: Product;
    onToggleFavorite: (id: string) => void;
}

const ProductCard: FC<IProductCardProps> = ({ className = "", product, onToggleFavorite }) => {
    const { id, name, price, imageUrl, isExist, isSale, isNew, isHit, isDealer, isFavorite } = product;

    const clickAddToFavoriteHandler = useCallback(() => {
        onToggleFavorite(id);
    }, [id, onToggleFavorite]);

    return (
        <div className={classNames(s.card, { [s.sale]: isSale }, className)}>
            <div className={s.sale__container}>SALE</div>
            <Checkbox
                className={s.favorite_icon}
                checked={isFavorite}
                icon={<HeartIcon />}
                checkedIcon={<FilledHeartIcon />}
                onChange={clickAddToFavoriteHandler}
            />
            <div className={s.photo__container}>
                <div className={s.product_preview__container}>
                    <span>Посмотреть товар</span>
                </div>
                <img
                    className={s.photo}
                    src={imageUrl}
                    alt={"photo"}
                />
            </div>
            <span className={s.name}>{name}</span>
            {isExist ? (
                <>
                    <span className={s.price}>{formatNumber(price)}</span>
                    <div className={s.cart__container}>
                        <CartIcon />
                    </div>
                </>
            ) : (
                <div className={s.not_exist__text}>
                    <span>Нет в наличиии</span>
                    <a href={"#"}>Сообщить о поступлении</a>
                </div>
            )}
        </div>
    );
};

export default ProductCard;
