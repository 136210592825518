import { FC, ReactNode, useCallback, useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as ArrowIcon } from "../../assets/svg/toggle_arrow_up.svg";
import classNames from "classnames";
import s from "./CollapseInformatio.module.scss";

interface ICollapseInformation {
    toggleContainerClassName?: string;
    contentClassName?: string;
    defaultOpenState?: boolean;
    name: string;
    children: ReactNode;
}

const CollapseInformation: FC<ICollapseInformation> = ({
    toggleContainerClassName = "",
    contentClassName = "",
    defaultOpenState = true,
    name,
    children,
}) => {
    const [opened, setOpened] = useState(defaultOpenState);

    const toggleCollapseHandler = useCallback(() => {
        setOpened(prev => !prev);
    }, []);

    return (
        <div>
            <div
                className={classNames(s.name__container, toggleContainerClassName)}
                onClick={toggleCollapseHandler}
            >
                <div className={s.icon__container}>
                    <ArrowIcon className={classNames(s.icon, { [s.opened]: opened })} />
                </div>
                <span className={s.name}>{name}</span>
            </div>
            <Collapse
                isOpen={opened}
                className={contentClassName}
            >
                {children}
            </Collapse>
        </div>
    );
};

export default CollapseInformation;
