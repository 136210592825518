import { FC, useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ProductsGrid } from "../../components/ProductsGrid";
import { SearchBar } from "../../components/SearchBar";
import {
    changeAccountPage,
    getFavoritesRequest,
    toggleFavoriteRequest,
} from "../../redux/reducers/accountReducer/accountReducer";
import { getAccount } from "../../redux/selectors";
import s from "./AccountPage.module.scss";

const AccountPage: FC = () => {
    const { favoritesPending, username, favorites, pageCount, pageSize, currentPage } = useAppSelector(getAccount);
    const [searchText, setSearchText] = useState("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getFavoritesRequest({ pageSize, pageNumber: currentPage }));
    }, []);

    const toggleFavoriteHandler = useCallback((id: string) => {
        dispatch(toggleFavoriteRequest(id));
    }, []);

    const changePageHandler = useCallback((pageNumber: number) => {
        dispatch(changeAccountPage(pageNumber));
        dispatch(getFavoritesRequest({ pageSize, pageNumber }));
    }, []);

    return (
        <div>
            <h2 className={s.username}>{username}</h2>
            <h2 className={s.category_name}>Избранные товары</h2>
            <Row className={s.searchbar}>
                <SearchBar onChange={setSearchText} />
            </Row>
            <Row>
                <ProductsGrid
                    isLoading={favoritesPending}
                    products={favorites}
                    searchText={searchText}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    onChangePage={changePageHandler}
                    onToggleFavorite={toggleFavoriteHandler}
                />
            </Row>
        </div>
    );
};

export default AccountPage;
