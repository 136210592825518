import { createSlice } from "@reduxjs/toolkit";
import { ShopState } from "./types/shopState";
import { GetProductsFailure, GetProductsRequest, GetProductsSuccess } from "./types/actionTypes/getProductsTypes";
import { GetFilterFailure, GetFilterSuccess } from "./types/actionTypes/getFilterTypes";
import { SetFilterInventory, SetFilterParameter, SetFilterPrice } from "./types/actionTypes/setFilterTypes";
import { ChangeShopPage } from "./types/actionTypes/changeShopPage";
import { SetSearchMode } from "./types/actionTypes/setSearchMode";

const initialState: ShopState = {
    getProductsPending: false,
    getFilterPending: false,
    products: [],
    filterBuilder: {
        minPrice: 0,
        maxPrice: 0,
        brands: [],
        models: [],
        stocks: [],
        countries: [],
    },
    filterSearch: {
        inventory: {
            isExist: false,
            isOnRequest: false,
        },

        price: {
            minPrice: 0,
            maxPrice: 0,
        },

        brands: [],
        models: [],
        countries: [],
        stocks: [],
    },
    filter: {
        inventory: {
            isExist: false,
            isOnRequest: false,
        },

        price: {
            minPrice: 0,
            maxPrice: 0,
        },

        brands: [],
        models: [],
        countries: [],
        stocks: [],
    },
    searchMode: 0,
    currentPage: 1,
    pageCount: 1,
    error: "",
};

export const ShopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        getProductsRequest(state) {
            state.getProductsPending = true;
            state.filterSearch = state.filter;
            state.error = null;
        },
        gerProductsAsyncRequest(state) {
            state.getProductsPending = true;
            state.error = null;
        },
        getProductsSuccess(state, action: GetProductsSuccess) {
            state.getProductsPending = false;
            state.pageCount = action.payload.pageCount;
            state.products = action.payload.products;
        },
        getProductsFailure(state, action: GetProductsFailure) {
            state.getProductsPending = false;
            state.error = action.payload;
        },

        getFilterRequest(state) {
            state.getFilterPending = true;
            state.error = null;
        },

        getFilterSuccess(state, action: GetFilterSuccess) {
            state.getFilterPending = false;
            state.filterBuilder = action.payload;
            state.filter.price.minPrice = action.payload.minPrice;
            state.filter.price.maxPrice = action.payload.maxPrice;
        },

        getFilterFailure(state, action: GetFilterFailure) {
            state.getFilterPending = false;
            state.error = action.payload;
        },

        setFilterInventory(state, action: SetFilterInventory) {
            state.filter.inventory[action.payload.field] = action.payload.value;
        },
        setFilterPrice(state, action: SetFilterPrice) {
            state.filter.price = action.payload;
        },
        setFilterParameter(state, action: SetFilterParameter) {
            const { field, value } = action.payload;
            const parameter = state.filter[field] as string[] | number[];
            const existIndex = parameter.findIndex(v => v === value);

            if (existIndex === -1) {
                //eslint-disable-next-line
                //@ts-ignore
                parameter.push(value);
            } else {
                parameter.splice(existIndex, 1);
            }
        },
        clearFilter(state) {
            state.filter = {
                inventory: {
                    isExist: false,
                    isOnRequest: false,
                },

                price: {
                    minPrice: state.filterBuilder.minPrice,
                    maxPrice: state.filterBuilder.maxPrice,
                },

                brands: [],
                models: [],
                stocks: [],
                countries: [],
            };
        },

        changeShopPage(state, action: ChangeShopPage) {
            state.currentPage = action.payload;
        },

        setSearchMode(state, action: SetSearchMode) {
            state.searchMode = action.payload;
        },
    },
});

export const {
    getProductsRequest,
    getProductsSuccess,
    getProductsFailure,
    getFilterRequest,
    getFilterSuccess,
    getFilterFailure,
    setFilterInventory,
    setFilterPrice,
    setFilterParameter,
    clearFilter,
    changeShopPage,
    setSearchMode,
} = ShopSlice.actions;

export default ShopSlice.reducer;
