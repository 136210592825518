import { FC, FormEvent, useCallback, useState } from "react";
import { Input } from "../../uiKit/Input";
import { Button } from "../../uiKit/Button";
import classNames from "classnames";
import s from "./SearchBar.module.scss";

interface ISearchBarProps {
    inputContainerClassName?: string;
    inputClassName?: string;
    buttonClassName?: string;
    defaultValue?: string;
    onChange: (newValue: string) => void;
}
const SearchBar: FC<ISearchBarProps> = ({
    inputContainerClassName = "",
    inputClassName = "",
    buttonClassName = "",
    defaultValue = "",
    onChange,
}) => {
    const [value, setValue] = useState(defaultValue);

    const clickSearchHandler = () => {
        onChange(value);
    };

    const submitFormHandler = useCallback((e: FormEvent) => e.preventDefault(), []);

    return (
        <form
            className={s.search_bar}
            onSubmit={submitFormHandler}
        >
            <Input
                className={s.searchbar__input}
                labelClassName={s.searchbar__input_label}
                containerClassName={classNames(s.searchbar__input_container, inputContainerClassName)}
                value={value}
                placeholder={"Введите марку"}
                label={"Поиск по названию товара"}
                onChange={setValue}
            />
            <Button
                className={s.searchbar__button}
                onClick={clickSearchHandler}
            >
                Искать
            </Button>
        </form>
    );
};

export default SearchBar;
