import { ChangeEvent, FC, useCallback } from "react";
import { formatNumber } from "../../helpers/formatNumber";
import s from "./Input.module.scss";

interface IInputNumberProps {
    value: number;
    onChange: (newValue: number) => void;
}

const InputNumber: FC<IInputNumberProps> = ({ value, onChange }) => {
    const formattedValue = formatNumber(value, false);
    const changeValueHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value.split(" ").join(""));
            if (isNaN(newValue)) return;
            onChange(newValue);
        },
        [value, onChange]
    );

    return (
        <input
            className={s.input_number}
            value={formattedValue}
            onChange={changeValueHandler}
        />
    );
};

export default InputNumber;
