import { ToggleFavoriteRequest } from "./types/actionTypes/toggleFavoriteTypes";
import { Product } from "../shopReducer/types/product";
import { call, put, select } from "redux-saga/effects";
import { RootState } from "../../store";
import { accountApi } from "../../../api/accountApi";
import { AxiosResponse } from "axios";
import {
    getFavoritesFailure,
    getFavoritesRequest,
    getFavoritesSuccess,
    getProfileFailure,
    getProfileSuccess,
    toggleFavoriteFailure,
    toggleFavoriteSuccess,
} from "./accountReducer";
import { getProductsSuccess } from "../shopReducer/shopReducer";
import { ShopState } from "../shopReducer/types/shopState";
import { ProfileResponse } from "./types/profileResponse";
import { convertProduct } from "../../helpers/converter";
import { FavoriteResponse } from "./types/favoriteResponse";
import { GetFavoritesRequest } from "./types/actionTypes/getFavoritesTypes";
import { getAccount, getShop } from "../../selectors";

export function* getProfileWorker() {
    const response: AxiosResponse<ProfileResponse> = yield call(accountApi.getProfile);
    const { pageNumber, pageSize } = yield select(getAccount);
    yield put(getFavoritesRequest({ pageNumber, pageSize }));
    if (response) {
        yield put(getProfileSuccess(response.data.username));
    } else {
        yield put(getProfileFailure("Неизсветная ошибка"));
    }
}

export function* getFavoritesWorker({ payload }: GetFavoritesRequest) {
    const response: AxiosResponse<FavoriteResponse> = yield call(accountApi.getFavorites, payload);
    if (response) {
        const favorites = response.data.products.map(p => convertProduct(p));
        const { products, pageCount }: ShopState = yield select(getShop);
        const productsCopy = products.map(p => ({ ...p, isFavorite: favorites.some(f => f.id === p.id) }));
        yield put(getFavoritesSuccess({ products: favorites, pageCount: response.data.pageCount }));
        yield put(getProductsSuccess({ products: productsCopy, pageCount }));
    } else {
        yield put(getFavoritesFailure("Нет данных"));
    }
}

export function* toggleFavoriteWorker({ payload }: ToggleFavoriteRequest) {
    const { products, pageCount }: ShopState = yield select(getShop);
    const productsCopy: Product[] = products.map(p => ({ ...p }));
    const productIndex = productsCopy.findIndex(p => p.id === payload);
    const fetchFunc = productIndex === -1 ? accountApi.setFavorite : accountApi.removeFavorite;
    const response: AxiosResponse<void> = yield call(fetchFunc, payload);
    if (response) {
        const changedProduct = { ...productsCopy[productIndex] };
        changedProduct.isFavorite = !changedProduct.isFavorite;
        productsCopy[productIndex] = changedProduct;
        yield put(getProductsSuccess({ products: productsCopy, pageCount }));
        yield put(toggleFavoriteSuccess());
    } else {
        yield put(toggleFavoriteFailure("Не получилось"));
    }
}
