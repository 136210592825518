import { FC, useCallback, useContext, useEffect } from "react";
import { ModalMain } from "../../uiKit/ModalMain";
import { AuthModalContext } from "../../layout";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../uiKit/Input";
import { emailValidate } from "../../utils/emailValidate";
import { Button, ButtonText } from "../../uiKit/Button";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/cross.svg";
import s from "./AuthorizationPage.module.scss";
import { LoginForm } from "../../redux/reducers/authenticationReducer/types/loginForm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loginRequest } from "../../redux/reducers/authenticationReducer/authenticationReducer";
import { getAuthenticate } from "../../redux/selectors";

const LoginPage: FC = () => {
    const { loginIsOpen, toggleRegistrationModalOpenHandler, toggleLoginModalOpenHandler } =
        useContext(AuthModalContext);
    const { loginPending, loginError } = useAppSelector(getAuthenticate);
    const dispatch = useAppDispatch();

    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm<LoginForm>();

    const toggleModalHandler = useCallback(() => {
        reset();
        toggleLoginModalOpenHandler?.();
    }, []);

    useEffect(() => {
        if (!loginPending && !loginError) {
            toggleModalHandler();
        }
    }, [loginPending, loginError]);

    const submitHandler = useCallback((data: LoginForm) => {
        dispatch(loginRequest(data));
    }, []);

    const goToRegistrationHandler = useCallback(() => {
        toggleModalHandler();
        toggleRegistrationModalOpenHandler?.();
    }, []);

    return (
        <ModalMain
            isOpen={loginIsOpen}
            toggle={toggleModalHandler}
            bodyClassName={s.body}
        >
            <CloseIcon
                className={s.close_icon}
                onClick={toggleModalHandler}
            />
            <LogoIcon />
            <form
                className={s.form}
                onSubmit={handleSubmit(submitHandler)}
            >
                <h5 className={s.title}>Вход</h5>
                <Controller
                    control={control}
                    name={"email"}
                    rules={{
                        required: true,
                        validate: emailValidate,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            containerClassName={s.input__container}
                            value={value}
                            placeholder={"Email"}
                            invalid={!!errors.email || !!loginError}
                            onChange={onChange}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={"password"}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            containerClassName={s.input__container}
                            value={value}
                            placeholder={"Пароль"}
                            invalid={!!errors.password || !!loginError}
                            type={"password"}
                            onChange={onChange}
                        />
                    )}
                />
                {loginError && <span className={s.error_message}>{loginError}</span>}
                <Button disabled={loginPending}>Войти</Button>
                <ButtonText
                    text={"Регистрация"}
                    disabled={loginPending}
                    onClick={goToRegistrationHandler}
                />
            </form>
        </ModalMain>
    );
};

export default LoginPage;
