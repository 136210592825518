import axios from "axios";

const baseApi = axios.create({
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

baseApi.interceptors.request.use(config => {
    const token = localStorage.getItem("@ACCESS_TOKEN");
    config.headers[`Access-Control-Allow-Credentials`] = true;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
});

baseApi.interceptors.response.use(
    config => {
        return config;
    },
    async error => {
        const originalRequest = error.config;

        if (!error.response) {
            return;
        }

        if (error.response.status >= 400 && error.response.status !== 401) {
            return error.response;
        }

        if (error.response.status === 401 && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response: any = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BACKEND_URl}/refresh`,
                    withCredentials: true,
                });

                localStorage.setItem("@ACCESS_TOKEN", response.data.token);
                return baseApi.request(originalRequest);
            } catch (error) {
                console.log(error);
            }
        }

        if (error.response.status === 401 && error.config?._isRetry) {
            localStorage.removeItem("@ACCESS_TOKEN");
        }
    }
);

export default baseApi;
