import { FC, useCallback } from "react";
import classNames from "classnames";
import s from "./Button.module.scss";

interface IButtonTextProps {
    className?: string;
    text: string;
    disabled?: boolean;
    onClick?: () => void;
}

const ButtonText: FC<IButtonTextProps> = ({ className = "", text, disabled = false, onClick }) => {
    const clickHandler = useCallback(() => {
        if (!disabled) onClick?.();
    }, [disabled]);

    return (
        <span
            className={classNames(s.button_text, { [s.disabled]: disabled }, className)}
            onClick={clickHandler}
        >
            {text}
        </span>
    );
};

export default ButtonText;
