import { FilterState } from "../redux/reducers/shopReducer/types/filterState";
import { FilterBuilder } from "../redux/reducers/shopReducer/types/filtetBuilder";

export function filterIsDirty(filter: FilterState, filterBuilder: FilterBuilder) {
    const priceIsDirty =
        filter.price.minPrice !== filterBuilder.minPrice || filter.price.maxPrice != filterBuilder.maxPrice;
    const inventoryIsDirty = filter.inventory.isExist || filter.inventory.isOnRequest;
    const alsoIsDirty =
        filter.stocks.length > 0 || filter.models.length > 0 || filter.brands.length > 0 || filter.countries.length > 0;
    return priceIsDirty || inventoryIsDirty || alsoIsDirty;
}
