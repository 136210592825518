import { takeLatest, takeEvery, all, call } from "redux-saga/effects";
import { getFavoritesRequest, getProfileRequest, toggleFavoriteRequest } from "./accountReducer";
import { getFavoritesWorker, getProfileWorker, toggleFavoriteWorker } from "./workers";

function* getProfileWatcher() {
    yield takeLatest(getProfileRequest, getProfileWorker);
}

function* getFavoritesWatcher() {
    yield takeLatest(getFavoritesRequest, getFavoritesWorker);
}

function* toggleFavoriteWatcher() {
    yield takeEvery(toggleFavoriteRequest, toggleFavoriteWorker);
}

export function* accountWatcher() {
    yield all([call(getProfileWatcher), call(getFavoritesWatcher), call(toggleFavoriteWatcher)]);
}
