import BaseApi from "./baseApi";
import { AxiosResponse } from "axios";
import { ProfileResponse } from "../redux/reducers/accountReducer/types/profileResponse";
import { FavoriteResponse } from "../redux/reducers/accountReducer/types/favoriteResponse";
import { GetFavoritesRequestPayload } from "../redux/reducers/accountReducer/types/actionTypes/getFavoritesTypes";

export const accountApi = {
    getProfile: (): Promise<AxiosResponse<ProfileResponse>> => {
        return BaseApi.get(`/profile`);
    },

    getFavorites: (params: GetFavoritesRequestPayload): Promise<AxiosResponse<FavoriteResponse>> => {
        return BaseApi.get("/favourites", { params });
    },

    setFavorite: (id: string): Promise<AxiosResponse<void>> => {
        return BaseApi.put(`/api/products/${id}/setFavourite`);
    },

    removeFavorite: (id: string): Promise<AxiosResponse<void>> => {
        return BaseApi.put(`/api/products/${id}/removeFavourite`);
    },
} as const;
