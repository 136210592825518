import { call, put, select } from "redux-saga/effects";
import { shopApi } from "../../../api/shopApi";
import { AxiosResponse } from "axios";
import { ProductResponse } from "./types/productResponse";
import { getFilterFailure, getFilterSuccess, getProductsFailure, getProductsSuccess } from "./shopReducer";
import {
    convertFilterResponseToFilterBuilder,
    convertFilterStateToFilterSearch,
    convertProduct,
} from "../../helpers/converter";
import { FilterResponse } from "./types/filterResponse";
import { FilterBuilder } from "./types/filtetBuilder";
import { FilterSearch } from "./types/filterSearch";
import { ShopState } from "./types/shopState";
import { getShop } from "../../selectors";

export function* getFilterWorker() {
    const response: AxiosResponse<FilterResponse> = yield call(shopApi.getFilter);
    if (response) {
        const result: FilterBuilder = yield call(convertFilterResponseToFilterBuilder, response.data);
        yield put(getFilterSuccess(result));
    } else {
        yield put(getFilterFailure("фильтр не был получен"));
    }
}

export function* getProductsWorker() {
    const { currentPage, filterSearch, searchMode }: ShopState = yield select(getShop);
    const currentFilterSearch: FilterSearch = yield call(
        convertFilterStateToFilterSearch,
        filterSearch,
        currentPage,
        8,
        searchMode
    );
    const response: AxiosResponse<{ pageCount: number; products: ProductResponse[] }> = yield call(
        shopApi.getProducts,
        currentFilterSearch
    );
    if (response) {
        const products = response.data.products.map(p => convertProduct(p));
        yield put(getProductsSuccess({ pageCount: response.data.pageCount, products }));
    } else {
        yield put(getProductsFailure("no data"));
    }
}
