import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Advertisement } from "../../components/Advertisement";
import { Slider } from "../../components/Slider";
import { SearchBar } from "../../components/SearchBar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
    changeShopPage,
    getFilterRequest,
    getProductsRequest,
    setSearchMode,
} from "../../redux/reducers/shopReducer/shopReducer";
import { FilterContainer } from "../../components/Filter";
import banner from "../../assets/images/banner.png";
import add_product from "../../assets/images/ad_product.png";
import { Select } from "../../uiKit/Select";
import { Button } from "../../uiKit/Button";
import { AuthModalContext } from "../../layout";
import { toggleFavoriteRequest } from "../../redux/reducers/accountReducer/accountReducer";
import { ProductsGrid } from "../../components/ProductsGrid";
import { getAuthenticate, getShop } from "../../redux/selectors";
import s from "./HydrocyclesPage.module.scss";

const items = [
    {
        id: 1,
        src: banner,
        altText: "",
        caption: "",
    },
    {
        id: 2,
        src: banner,
        altText: "",
        caption: "",
    },
    {
        id: 3,
        src: banner,
        altText: "",
        caption: "",
    },
];

const selectModeOptions = ["По популярности", "Сначала дорогие", "Сначала дешёвые"];

const HydrocyclesPage: FC = () => {
    const [searchText, setSearchText] = useState("");
    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
    const { getProductsPending, products, pageCount, currentPage, searchMode } = useAppSelector(getShop);
    const { isAuth } = useAppSelector(getAuthenticate);
    const { toggleLoginModalOpenHandler } = useContext(AuthModalContext);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getFilterRequest());
        dispatch(getProductsRequest());
    }, []);

    const changePageHandler = useCallback((newPage: number) => {
        dispatch(changeShopPage(newPage));
        dispatch(getProductsRequest());
    }, []);

    const changeSearchMode = useCallback((newValue: string, index: number) => {
        dispatch(setSearchMode(index));
        dispatch(getProductsRequest());
    }, []);

    const toggleFavoriteHandler = useCallback(
        (productId: string) => {
            if (!isAuth) {
                toggleLoginModalOpenHandler?.();
            } else {
                dispatch(toggleFavoriteRequest(productId));
            }
        },
        [isAuth]
    );

    const toggleFilterModalOpen = useCallback(() => setFilterModalIsOpen(prev => !prev), []);

    return (
        <div>
            <Row className={s.advertisement__section}>
                <Col
                    xl={8}
                    className={s.slider_container}
                >
                    <Slider
                        imgClassName={s.slide}
                        items={items}
                    />
                </Col>
                <Col
                    xl={3}
                    className={s.advertisement__container}
                >
                    <Advertisement
                        className={s.main_advertisement}
                        name={"ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)"}
                        newPrice={190000}
                        oldPrice={230000}
                        productImageUrl={add_product}
                        dateEnd={new Date()}
                    />
                </Col>
            </Row>
            <Row className={s.searchbar__section}>
                <SearchBar onChange={setSearchText} />
            </Row>
            <Row style={{ marginBottom: 30 }}>
                <Col xl={12}>
                    <h1 className={s.main_title}>Гидроциклы</h1>
                    <div className={s.view_filter__section}>
                        <div className={s.view_filter__container}>
                            <Select
                                value={selectModeOptions.find((o, i) => i === searchMode)!}
                                inputClassName={s.view_filter__select}
                                optionClassName={s.view_filter__select_option}
                                options={selectModeOptions}
                                onChange={changeSearchMode}
                            />
                        </div>
                        <Button
                            className={s.toggle_filter__button}
                            onClick={toggleFilterModalOpen}
                        >
                            Фильтр
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className={s.main_content}>
                <FilterContainer
                    modalIsOpen={filterModalIsOpen}
                    toggleModal={toggleFilterModalOpen}
                />
                <ProductsGrid
                    isLoading={getProductsPending}
                    products={products}
                    searchText={searchText}
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onToggleFavorite={toggleFavoriteHandler}
                    onChangePage={changePageHandler}
                />
            </div>
        </div>
    );
};

export default HydrocyclesPage;
