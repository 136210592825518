import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import s from "./Button.module.scss";

interface IButtonProps {
    className?: string;
    type?: "submit" | "button";
    children?: ReactNode;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FC<IButtonProps> = props => {
    const { className = "", type = "submit", ...rest } = props;
    return (
        <button
            className={classNames(s.button, className)}
            type={type}
            {...rest}
        />
    );
};

export default Button;
