import { RegistrationRequest } from "./types/actionTypes/registrationTypes";
import { call, put } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
    checkAuthFailure,
    checkAuthRequest,
    checkAuthSuccess,
    loginFailure,
    loginSuccess,
    logoutFailure,
    logoutRequest,
    logoutSuccess,
    registrationFailure,
    registrationSuccess,
} from "./authenticationReducer";
import { LoginRequest } from "./types/actionTypes/loginTypes";
import { LoginResponse } from "./types/loginResponse";
import { authenticationApi } from "../../../api/authenticationApi";
import { ErrorResponse } from "./types/errorResponse";
import { isErrorResponse } from "../../../helpers/instanceHelpers";

export function* registrationWorker({ payload }: RegistrationRequest) {
    const res: AxiosResponse<LoginResponse | ErrorResponse> = yield call(authenticationApi.registration, payload);
    if (!isErrorResponse(res.data)) {
        localStorage.setItem("@ACCESS_TOKEN", res.data.token);
        yield put(registrationSuccess());
    } else {
        yield put(registrationFailure(res.data));
    }
}

export function* loginWorker({ payload }: LoginRequest) {
    const res: AxiosResponse<LoginResponse | string> = yield call(authenticationApi.login, payload);
    if (res.status < 400 && typeof res.data === "object") {
        localStorage.setItem("@ACCESS_TOKEN", res.data.token);
        yield put(loginSuccess());
    } else {
        yield put(loginFailure(res.data as string));
    }
}

export function* checkAuthWorker() {
    const token = localStorage.getItem("@ACCESS_TOKEN");
    if (token) {
        const response: AxiosResponse<LoginResponse> = yield call(authenticationApi.refresh);
        if (response) {
            localStorage.setItem("@ACCESS_TOKEN", response.data.token);
            yield put(checkAuthSuccess(true));
        } else {
            yield put(checkAuthFailure(""));
            localStorage.removeItem("@ACCESS_TOKEN");
        }
    } else {
        yield put(checkAuthSuccess(false));
    }
}

export function* logoutWorker() {
    const response: { token: boolean } = yield call(authenticationApi.logout);
    if (response) {
        yield put(logoutSuccess());
    } else {
        yield put(logoutFailure(""));
    }
}
