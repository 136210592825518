import { ProductResponse } from "../reducers/shopReducer/types/productResponse";
import { Product } from "../reducers/shopReducer/types/product";
import { FilterBuilder } from "../reducers/shopReducer/types/filtetBuilder";
import { FilterResponse } from "../reducers/shopReducer/types/filterResponse";
import { MainModel } from "../reducers/shopReducer/types/mainModel";
import { FilterState } from "../reducers/shopReducer/types/filterState";
import { FilterSearch } from "../reducers/shopReducer/types/filterSearch";

export function convertProduct(productResponse: ProductResponse): Product {
    const actions = productResponse.actions;
    const isSale = actions.some(a => a === 0);
    const isNew = actions.some(a => a === 1);
    const isHit = actions.some(a => a === 2);
    const isDealer = actions.some(a => a === 3);
    return {
        id: productResponse.id,
        name: productResponse.title,
        imageUrl: productResponse.img,
        price: productResponse.price,
        isExist: productResponse.isInInventory,
        isSale,
        isNew,
        isHit,
        isDealer,
        isFavorite: productResponse.isFavourite,
    };
}

export function convertFilterResponseToFilterBuilder(filterResponse: FilterResponse): FilterBuilder {
    const countries: MainModel[] = [];
    const brands: MainModel[] = [];
    const models: MainModel[] = [];
    filterResponse.countries.forEach(c => {
        countries.push({ id: c.id, title: c.title });
        c.brands.forEach(b => {
            brands.push({ id: b.id, title: b.title });
            b.models.forEach(m => models.push(m));
        });
    });
    return {
        minPrice: filterResponse.minPrice,
        maxPrice: filterResponse.maxPrice,
        stocks: [
            {
                id: 0,
                title: "SALE",
            },
            {
                id: 1,
                title: "NEW",
            },
            {
                id: 2,
                title: "HIT",
            },
            {
                id: 3,
                title: "DEALER",
            },
        ],
        countries,
        brands,
        models,
    };
}

export function convertFilterStateToFilterSearch(
    filterState: FilterState,
    pageNumber: number,
    pageSize: number,
    searchMode: number
): FilterSearch {
    return {
        MinPrice: filterState.price.minPrice === 0 ? undefined : filterState.price.minPrice,
        MaxPrice: filterState.price.maxPrice === 0 ? undefined : filterState.price.maxPrice,
        IsInventory: filterState.inventory.isExist ? filterState.inventory.isExist : undefined,
        IsOnRequest: filterState.inventory.isOnRequest ? filterState.inventory.isOnRequest : undefined,
        Brands: filterState.brands,
        Models: filterState.models,
        Counties: filterState.countries,
        Actions: filterState.stocks,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Mode: searchMode,
    };
}
