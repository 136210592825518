import { createSlice } from "@reduxjs/toolkit";
import { AdvertisementState } from "./types/AdvertisementState";

const initialState: AdvertisementState = {
    mostSales: [],
};

export const AdvertisementSlice = createSlice({
    name: "advertisement",
    initialState,
    reducers: {
        setAdvertisements(state, action) {
            state.mostSales = action.payload;
        },
    },
});

export const { setAdvertisements } = AdvertisementSlice.actions;

export default AdvertisementSlice.reducer;
