import React, { FC, useCallback, useEffect, useState } from "react";
import { Slider } from "@mui/material";
import { formatNumber } from "../../helpers/formatNumber";

interface IInputRange {
    className?: string;
    value: { min: number; max: number };
    min: number;
    max: number;
    onChange: (newValue: { min: number; max: number }) => void;
}
const InputRange: FC<IInputRange> = ({ className = "", value, min, max, onChange }) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        if (value.min != localValue.min || value.max != localValue.max) {
            setLocalValue(value);
        }
    }, [value]);

    const changeHandler = useCallback((e: Event, newValue: number | number[]) => {
        if (typeof newValue === "object") {
            setLocalValue({ min: newValue[0], max: newValue[1] });
        }
    }, []);

    const changeCommittedHandler = useCallback((e: React.SyntheticEvent | Event, newValue: number | number[]) => {
        if (typeof newValue === "object") {
            onChange({ min: newValue[0], max: newValue[1] });
        }
    }, []);

    return (
        <Slider
            className={className}
            value={[localValue.min, localValue.max]}
            onChange={changeHandler}
            onChangeCommitted={changeCommittedHandler}
            valueLabelDisplay="auto"
            valueLabelFormat={val => formatNumber(val, false)}
            size={"small"}
            min={min}
            max={max}
        />
    );
};

export default InputRange;
