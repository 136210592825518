import { ChangeEvent, FC, useCallback } from "react";
import classNames from "classnames";
import s from "./Input.module.scss";

interface IInputProps {
    className?: string;
    containerClassName?: string;
    labelClassName?: string;
    label?: string;
    placeholder?: string;
    type?: "text" | "password";
    invalid?: boolean;
    hint?: string;
    value: string;
    onChange: (newValue: string) => void;
}

const Input: FC<IInputProps> = ({
    containerClassName = "",
    className = "",
    labelClassName = "",
    label = "",
    placeholder,
    type = "text",
    invalid = false,
    hint,
    value,
    onChange,
}) => {
    const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value), []);

    return (
        <div className={classNames(s.container, containerClassName)}>
            {label && <label className={classNames(s.label, labelClassName)}>{label}</label>}
            <input
                className={classNames(s.input, { [s.invalid]: invalid }, className)}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={changeHandler}
            />
            {hint && <p className={classNames(s.hint, { [s.invalid]: invalid })}>{hint}</p>}
        </div>
    );
};

export default Input;
