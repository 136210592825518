import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as HeartIcon } from "../../assets/svg/heart.svg";
import { ReactComponent as CartIcon } from "../../assets/svg/cart.svg";
import { ReactComponent as HouseIcon } from "../../assets/svg/house.svg";
import { ReactComponent as PercentIcon } from "../../assets/svg/percent.svg";
import { ReactComponent as BoxIcon } from "../../assets/svg/box.svg";

export const menuItems = [
    {
        title: "Войти",
        icon: <UserIcon />,
    },
    { title: "Регистрация", icon: <UserIcon /> },
    {
        title: "Избранное",
        icon: <HeartIcon />,
    },
    {
        title: "Корзина",
        icon: <CartIcon />,
    },
    {
        title: "Магазин",
        icon: <HouseIcon />,
    },
    {
        title: "Акции",
        icon: <PercentIcon />,
    },
    {
        title: "Доставка и оплата",
        icon: <BoxIcon />,
    },
];
