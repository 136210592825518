import { FC, useCallback, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { CollapseInformation } from "../../uiKit/CollapseInformation";
import { Input, InputNumber, InputRange } from "../../uiKit/Input";
import { CheckboxBlock } from "../../uiKit/CheckboxBlock";
import { Button, ButtonText } from "../../uiKit/Button";
import { FilterState } from "../../redux/reducers/shopReducer/types/filterState";
import { FilterBuilder } from "../../redux/reducers/shopReducer/types/filtetBuilder";
import classNames from "classnames";
import { filterIsDirty } from "../../helpers/equalsFilters";
import s from "./Filter.module.scss";

interface IFilterProps {
    className?: string;
    filter: FilterState;
    filterBuilder: FilterBuilder;
    onSetFilterPrice: (newValue: { minPrice: number; maxPrice: number }) => void;
    onSetFilterInventory: (fieldName: string, value: boolean) => void;
    onSetFilterParameter: (fieldName: string, value: string | number) => void;
    onSubmitFilter: () => void;
    onClearFilter: () => void;
}

const Filter: FC<IFilterProps> = ({
    className = "",
    filter,
    filterBuilder,
    onSetFilterPrice,
    onSetFilterInventory,
    onSetFilterParameter,
    onSubmitFilter,
    onClearFilter,
}) => {
    const [search, setSearch] = useState("");
    const filterDirty = filterIsDirty(filter, filterBuilder);

    const setFilterPriceHandler = useCallback((newValue: { min: number; max: number }) => {
        const result = { minPrice: newValue.min, maxPrice: newValue.max };
        onSetFilterPrice(result);
    }, []);

    const setFilterMinPriceHandler = useCallback(
        (newValue: number) => setFilterPriceHandler({ min: newValue, max: filter.price.maxPrice }),
        [filter.price.maxPrice]
    );

    const setFilterMaxPriceHandler = useCallback(
        (newValue: number) => setFilterPriceHandler({ min: filter.price.minPrice, max: newValue }),
        [filter.price.minPrice]
    );

    return (
        <div className={classNames(s.filter, className)}>
            <CollapseInformation name={"Наличие"}>
                <FormGroup className={s.checkbox__container}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size={"small"}
                                checked={filter.inventory.isExist}
                            />
                        }
                        label={"В наличии"}
                        onChange={() => onSetFilterInventory("isExist", !filter.inventory.isExist)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                size={"small"}
                                checked={filter.inventory.isOnRequest}
                            />
                        }
                        label={"Под заказ"}
                        onChange={() => onSetFilterInventory("isOnRequest", !filter.inventory.isOnRequest)}
                    />
                </FormGroup>
            </CollapseInformation>
            <CollapseInformation name={"Цена"}>
                <div className={s.select_price__container}>
                    <InputRange
                        className={s.select_price}
                        value={{ min: filter.price.minPrice, max: filter.price.maxPrice }}
                        max={filterBuilder.maxPrice}
                        min={filterBuilder.minPrice}
                        onChange={setFilterPriceHandler}
                    />
                    <div className={s.input_price__container}>
                        <div className={s.input_price}>
                            <span>От:</span>
                            <InputNumber
                                value={filter.price.minPrice}
                                onChange={setFilterMinPriceHandler}
                            />
                        </div>
                        <div className={s.input_price}>
                            <span>До:</span>
                            <InputNumber
                                value={filter.price.maxPrice}
                                onChange={setFilterMaxPriceHandler}
                            />
                        </div>
                    </div>
                </div>
            </CollapseInformation>
            <CollapseInformation name={"Бренд"}>
                <FormGroup className={s.checkbox__container}>
                    {filterBuilder.brands.map(b => (
                        <FormControlLabel
                            key={`brand-${b.id}`}
                            control={
                                <Checkbox
                                    value={b.id}
                                    checked={filter.brands.includes(b.id)}
                                    size={"small"}
                                />
                            }
                            label={b.title}
                            onChange={() => onSetFilterParameter("brands", b.id)}
                        />
                    ))}
                </FormGroup>
            </CollapseInformation>
            <CollapseInformation
                toggleContainerClassName={s.model}
                name={"Модель"}
            >
                <Input
                    className={s.input}
                    value={search}
                    placeholder={"Введите модель"}
                    onChange={setSearch}
                />
                <FormGroup className={s.checkbox__container}>
                    {filterBuilder.models
                        .filter(m => m.title.toLowerCase().includes(search.toLowerCase()))
                        .map(m => (
                            <FormControlLabel
                                key={`model-${m.id}`}
                                control={
                                    <Checkbox
                                        value={m.id}
                                        checked={filter.models.includes(m.id)}
                                        size={"small"}
                                    />
                                }
                                label={m.title}
                                onChange={() => onSetFilterParameter("models", m.id)}
                            />
                        ))}
                </FormGroup>
            </CollapseInformation>
            <CollapseInformation
                name={"Акции"}
                toggleContainerClassName={s.model}
            >
                <div className={s.stocks}>
                    {filterBuilder.stocks.map(s => {
                        return (
                            <CheckboxBlock
                                key={`stock-${s.id}-${s.title}`}
                                text={s.title}
                                checked={filter.stocks.includes(s.id)}
                                onClick={() => onSetFilterParameter("stocks", s.id)}
                            />
                        );
                    })}
                </div>
            </CollapseInformation>
            <CollapseInformation name={"Страны"}>
                <FormGroup className={s.checkbox__container}>
                    {filterBuilder.countries.map(c => (
                        <FormControlLabel
                            key={`country-${c.id}`}
                            control={
                                <Checkbox
                                    value={c.id}
                                    checked={filter.countries.includes(c.id)}
                                    size={"small"}
                                />
                            }
                            label={c.title}
                            onChange={() => onSetFilterParameter("countries", c.id)}
                        />
                    ))}
                </FormGroup>
            </CollapseInformation>
            <div className={s.submit_filter__container}>
                <Button
                    className={s.submit_filter__button}
                    disabled={!filterDirty}
                    onClick={onSubmitFilter}
                >
                    Выбрать
                </Button>
                <ButtonText
                    text={"сбросить фильтр"}
                    disabled={!filterDirty}
                    onClick={onClearFilter}
                />
            </div>
        </div>
    );
};

export default Filter;
