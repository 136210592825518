import { FC, useCallback, useEffect, useState } from "react";
import Filter from "./Filter";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "../../uiKit/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
    clearFilter,
    getProductsRequest,
    setFilterInventory,
    setFilterParameter,
    setFilterPrice,
} from "../../redux/reducers/shopReducer/shopReducer";
import { getShop } from "../../redux/selectors";
import s from "./Filter.module.scss";

interface IFilterContainerProps {
    modalIsOpen?: boolean;
    toggleModal?: () => void;
}

const FilterContainer: FC<IFilterContainerProps> = ({ modalIsOpen, toggleModal }) => {
    const { getFilterPending, filter, filterBuilder } = useAppSelector(getShop);
    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modalIsOpen !== undefined) setFilterModalIsOpen(modalIsOpen);
    }, [modalIsOpen]);

    const setFilterInventoryHandler = useCallback(
        (field: string, value: boolean) => dispatch(setFilterInventory({ field, value })),
        []
    );

    const setFilterPriceHandler = useCallback(
        (newValue: { minPrice: number; maxPrice: number }) => dispatch(setFilterPrice(newValue)),
        []
    );

    const setFilterParameterHandler = useCallback(
        (field: string, value: string | number) => dispatch(setFilterParameter({ field, value })),
        []
    );

    const submitFilterHandler = useCallback(() => {
        setFilterModalIsOpen(false);
        dispatch(getProductsRequest());
    }, []);

    const clearFilterHandler = useCallback(() => {
        setFilterModalIsOpen(false);
        dispatch(clearFilter());
        dispatch(getProductsRequest());
    }, [filter]);

    const toggleFilterModalOpen = useCallback(() => {
        setFilterModalIsOpen(prev => !prev);
        toggleModal?.();
    }, []);

    return (
        <div>
            {!modalIsOpen && !toggleModal && (
                <Button
                    className={s.toggle_filter__button}
                    onClick={toggleFilterModalOpen}
                >
                    Фильтр
                </Button>
            )}
            <Filter
                filter={filter}
                filterBuilder={filterBuilder}
                onSetFilterPrice={setFilterPriceHandler}
                onSetFilterInventory={setFilterInventoryHandler}
                onSetFilterParameter={setFilterParameterHandler}
                onSubmitFilter={submitFilterHandler}
                onClearFilter={clearFilterHandler}
            />
            <Modal
                isOpen={filterModalIsOpen}
                centered={true}
                toggle={toggleFilterModalOpen}
                scrollable={true}
            >
                <ModalHeader toggle={toggleFilterModalOpen}>Фильтр</ModalHeader>
                <ModalBody>
                    <Filter
                        className={s.filter_modal}
                        filter={filter}
                        filterBuilder={filterBuilder}
                        onSetFilterPrice={setFilterPriceHandler}
                        onSetFilterInventory={setFilterInventoryHandler}
                        onSetFilterParameter={setFilterParameterHandler}
                        onSubmitFilter={submitFilterHandler}
                        onClearFilter={clearFilterHandler}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default FilterContainer;
