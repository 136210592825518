import baseApi from "./baseApi";
import { RegistrationForm } from "../redux/reducers/authenticationReducer/types/registrationForm";
import { LoginForm } from "../redux/reducers/authenticationReducer/types/loginForm";
import { AxiosResponse } from "axios";
import { LoginResponse } from "../redux/reducers/authenticationReducer/types/loginResponse";
import { ErrorResponse } from "../redux/reducers/authenticationReducer/types/errorResponse";

export const authenticationApi = {
    login: (data: LoginForm): Promise<AxiosResponse<LoginResponse | string>> => {
        return baseApi.post("/login", data);
    },

    registration: (data: RegistrationForm): Promise<AxiosResponse<LoginResponse | ErrorResponse>> => {
        return baseApi.post("/registration", data);
    },

    refresh: (): Promise<AxiosResponse<LoginResponse>> => {
        return baseApi.get("/refresh");
    },

    logout: () => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    token: true,
                });
            }, 300);
        });
    },
} as const;
