import { FC, useCallback, useContext, useEffect } from "react";
import { ModalMain } from "../../uiKit/ModalMain";
import { AuthModalContext } from "../../layout";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../uiKit/Input";
import { emailValidate } from "../../utils/emailValidate";
import { Button, ButtonText } from "../../uiKit/Button";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/cross.svg";
import { RegistrationForm } from "../../redux/reducers/authenticationReducer/types/registrationForm";
import s from "./AuthorizationPage.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { registrationRequest } from "../../redux/reducers/authenticationReducer/authenticationReducer";
import { getAuthenticate } from "../../redux/selectors";

const RegistrationPage: FC = () => {
    const { registrationIsOpen, toggleRegistrationModalOpenHandler, toggleLoginModalOpenHandler } =
        useContext(AuthModalContext);
    const { registrationPending, registrationError } = useAppSelector(getAuthenticate);
    const dispatch = useAppDispatch();

    const {
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm<RegistrationForm>();

    const toggleModalHandler = useCallback(() => {
        reset();
        toggleRegistrationModalOpenHandler?.();
    }, []);

    useEffect(() => {
        if (!registrationPending && !registrationError) {
            toggleModalHandler();
        }
    }, [registrationPending, registrationPending]);

    const submitHandler = useCallback((data: RegistrationForm) => {
        dispatch(registrationRequest(data));
    }, []);

    const goToLoginHandler = useCallback(() => {
        toggleModalHandler();
        toggleLoginModalOpenHandler?.();
    }, []);

    return (
        <ModalMain
            isOpen={registrationIsOpen}
            toggle={toggleModalHandler}
            bodyClassName={s.body}
        >
            <CloseIcon
                className={s.close_icon}
                onClick={toggleModalHandler}
            />
            <LogoIcon />
            <form
                className={s.form}
                onSubmit={handleSubmit(submitHandler)}
            >
                <h5 className={s.title}>Регистрация</h5>
                <Controller
                    control={control}
                    name={"name"}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            containerClassName={s.input__container}
                            value={value}
                            placeholder={"Username"}
                            onChange={onChange}
                            invalid={registrationError?.name === "name"}
                            hint={registrationError?.name === "name" ? registrationError.errorMessage : ""}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={"email"}
                    rules={{
                        required: true,
                        validate: emailValidate,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            containerClassName={s.input__container}
                            value={value}
                            placeholder={"Email"}
                            invalid={!!errors.email || registrationError?.name === "email"}
                            hint={registrationError?.name === "email" ? registrationError.errorMessage : ""}
                            onChange={onChange}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={"password"}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            containerClassName={s.input__container}
                            value={value}
                            placeholder={"Пароль"}
                            invalid={!!errors.password || registrationError?.name === "password"}
                            type={"password"}
                            hint={registrationError?.name === "password" ? registrationError.errorMessage : ""}
                            onChange={onChange}
                        />
                    )}
                />
                <Button disabled={registrationPending}>Войти</Button>
                <ButtonText
                    text={"Вход"}
                    disabled={registrationPending}
                    onClick={goToLoginHandler}
                />
            </form>
        </ModalMain>
    );
};

export default RegistrationPage;
