import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/svg/toggle_arrow_up.svg";
import classNames from "classnames";
import s from "./Select.module.scss";

interface ISelectProps {
    containerClassName?: string;
    inputClassName?: string;
    optionsContainerClassName?: string;
    optionClassName?: string;
    value: string;
    options: string[];
    placeholder?: string;
    onChange: (newValue: string, index: number) => void;
}

const Select: FC<ISelectProps> = ({
    containerClassName = "",
    inputClassName = "",
    optionsContainerClassName = "",
    optionClassName = "",
    value,
    options,
    placeholder,
    onChange,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isFocus, setFocus] = useState(false);

    const changeHandler = useCallback((value: string, index: number) => {
        onChange(value, index);
        setFocus(false);
    }, []);

    const clickViewHandler = useCallback(() => {
        ref.current?.focus();
        setFocus(prev => !prev);
    }, []);

    useEffect(() => {
        const clickOutsideHandler = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setFocus(false);
            }
        };
        document.addEventListener("click", clickOutsideHandler, false);
        return () => {
            document.removeEventListener("click", clickOutsideHandler, false);
        };
    }, []);

    return (
        <div
            className={classNames(s.inputWrap, containerClassName)}
            ref={ref}
        >
            <div
                className={classNames(s.input, { [s.focus]: isFocus }, inputClassName)}
                onClick={clickViewHandler}
            >
                {value === "" ? placeholder ?? "Не выбрано" : value}
                <div className={s.suffix}>
                    <div className={classNames(s.icon__container, { [s.rotated]: isFocus })}>
                        <ArrowIcon />
                    </div>
                </div>
            </div>
            <div className={classNames(s.options__container, { [s.opened]: isFocus }, optionsContainerClassName)}>
                {options.map((o, i) => {
                    return (
                        <div
                            key={i}
                            className={classNames(s.options__item, optionClassName)}
                            onClick={() => changeHandler(o, i)}
                        >
                            {o}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Select;
