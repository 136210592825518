import { createSlice } from "@reduxjs/toolkit";
import {
    ToggleFavoriteFailure,
    ToggleFavoriteRequest,
    ToggleFavoriteSuccess,
} from "./types/actionTypes/toggleFavoriteTypes";
import { AccountState } from "./types/accountState";
import { GetFavoritesFailure, GetFavoritesRequest, GetFavoritesSuccess } from "./types/actionTypes/getFavoritesTypes";
import { GetProfileFailure, GetProfileSuccess } from "./types/actionTypes/getProfileTypes";
import { ChangeAccountPage } from "./types/actionTypes/changeAccountPage";

const initialState: AccountState = {
    profilePending: false,
    favoritesPending: false,
    username: "",
    favorites: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    error: null,
};

export const AccountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        getProfileRequest(state) {
            state.profilePending = true;
        },
        getProfileSuccess(state, action: GetProfileSuccess) {
            state.profilePending = false;
            state.username = action.payload;
        },
        getProfileFailure(state, action: GetProfileFailure) {
            state.profilePending = false;
            state.error = action.payload;
        },

        getFavoritesRequest(state, action: GetFavoritesRequest) {
            state.favoritesPending = true;
        },
        getFavoritesSuccess(state, action: GetFavoritesSuccess) {
            state.favoritesPending = false;
            state.favorites = action.payload.products;
            state.pageCount = action.payload.pageCount;
        },
        getFavoritesFailure(state, action: GetFavoritesFailure) {
            state.favoritesPending = false;
            state.error = action.payload;
        },

        toggleFavoriteRequest(state, action: ToggleFavoriteRequest) {
            state.favoritesPending = true;
        },
        toggleFavoriteSuccess(state) {
            state.favoritesPending = false;
        },
        toggleFavoriteFailure(state, action: ToggleFavoriteFailure) {
            state.favoritesPending = false;
            state.error = action.payload;
        },

        changeAccountPage(state, action: ChangeAccountPage) {
            state.currentPage = action.payload;
        },
    },
});

export const {
    getProfileRequest,
    getProfileSuccess,
    getProfileFailure,
    getFavoritesRequest,
    getFavoritesSuccess,
    getFavoritesFailure,
    toggleFavoriteRequest,
    toggleFavoriteSuccess,
    toggleFavoriteFailure,
    changeAccountPage,
} = AccountSlice.actions;

export default AccountSlice.reducer;
