import { FC, useState } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";
import { SliderItem } from "../../types/sliderItem";
import classNames from "classnames";
import { isSliderImageItem } from "../../helpers/instanceHelpers";
import s from "./Slider.module.scss";

interface ISliderProps {
    className?: string;
    prevControlClassName?: string;
    nextControlClassName?: string;
    indicatorsClassName?: string;
    imgClassName?: string;
    itemsPreviewCount?: number;
    items: SliderItem[];
}

const Slider: FC<ISliderProps> = ({
    className = "",
    imgClassName = "",
    prevControlClassName = "",
    nextControlClassName = "",
    indicatorsClassName = "",
    itemsPreviewCount = 1,
    items,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const slides = [];
    for (let i = 0; i < items.length; i += itemsPreviewCount) {
        const elems = [];
        for (let j = 0; j < itemsPreviewCount; j++) {
            const item = items[i + j];
            if (!item) continue;
            elems.push(
                isSliderImageItem(item) ? (
                    <div key={`${i}-${j}`}>
                        <img
                            className={imgClassName}
                            src={item.src}
                            alt={item.altText}
                        />
                        <CarouselCaption
                            captionText={item.caption}
                            captionHeader={item.caption}
                        />
                    </div>
                ) : (
                    item.item
                )
            );
        }
        slides.push(
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`slide-${i}`}
            >
                <div className={s.items}>{elems}</div>
            </CarouselItem>
        );
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    return (
        <Carousel
            className={classNames(s.slider, className)}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            enableTouch={true}
        >
            <CarouselIndicators
                className={classNames(s.indicators, indicatorsClassName)}
                items={slides}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                className={prevControlClassName}
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                className={nextControlClassName}
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
};

export default Slider;
