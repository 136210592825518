import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "./authenticationReducer/authenticationReducer";
import accountReducer from "./accountReducer/accountReducer";
import shopReducer from "./shopReducer/shopReducer";
import advertisementsReducer from "./advertisementReducer/adverstisementReducer";

const rootReducer = combineReducers({
    authenticationReducer,
    accountReducer,
    shopReducer,
    advertisementsReducer,
});

export default rootReducer;
