import { FC, useCallback, useContext, useState } from "react";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { ReactComponent as LogoMiniIcon } from "../../assets/svg/logo_mini.svg";
import { ReactComponent as LocationIcon } from "../../assets/svg/location.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as CartIcon } from "../../assets/svg/cart.svg";
import { ReactComponent as BurgerIcon } from "../../assets/svg/burger.svg";
import { routers, routeUrls } from "../../routes/routers";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarMenu } from "../SidebarMenu";
import { useAppSelector } from "../../redux/hooks";
import { AuthModalContext } from "../index";
import { getAccount, getAuthenticate } from "../../redux/selectors";
import s from "./Header.module.scss";

const Header: FC = () => {
    const { toggleLoginModalOpenHandler } = useContext(AuthModalContext);
    const [menuIsOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuth } = useAppSelector(getAuthenticate);
    const { favorites, username } = useAppSelector(getAccount);
    const isUserAccount = location.pathname.includes(routeUrls.account);

    const toggleOpenMenu = useCallback(() => {
        setMenuOpen(prev => !prev);
    }, []);

    const clickProfileHandler = useCallback(() => {
        if (!isAuth) {
            toggleLoginModalOpenHandler?.();
        } else {
            navigate(routeUrls.account);
        }
    }, [isAuth]);

    return (
        <header className={s.header__container}>
            <div className={s.upper_menu}>
                <BurgerIcon
                    className={s.toggle_sidebar__icon}
                    onClick={toggleOpenMenu}
                />
                <SidebarMenu
                    isOpen={menuIsOpen}
                    toggle={toggleOpenMenu}
                />
                <div className={s.nav_menu}>
                    <span>Магазины</span>
                    <span>Акции</span>
                    <span>Доставка и оплата</span>
                </div>
                <div className={s.logo__container}>
                    <LogoMiniIcon className={s.logo_mini} />
                    <LogoIcon className={s.logo} />
                </div>
                <div className={s.user_information__container}>
                    <div className={s.user_location}>
                        <LocationIcon />
                        <span>Москва, ул. Науки 25</span>
                    </div>
                    <div className={s.user_information}>
                        <div
                            className={classNames(s.user, { [s.active]: isUserAccount })}
                            onClick={clickProfileHandler}
                        >
                            <UserIcon />
                            {isAuth ? <Link to={routeUrls.account}>{username}</Link> : <a>Войти</a>}
                        </div>
                        <div className={s.cart__container}>
                            <Link to={routeUrls.account}>
                                <CartIcon />
                            </Link>
                            {isAuth && <div className={s.badge}>{favorites.length}</div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.lower_menu}>
                {routers.map((r, i) => (
                    <Link
                        key={i}
                        className={classNames(s.route_link, { [s.active]: location.pathname.includes(r.path) })}
                        to={r.path}
                    >
                        {r.title}
                    </Link>
                ))}
            </div>
        </header>
    );
};

export default Header;
