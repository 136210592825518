import { FC, useState } from "react";
import { Container } from "reactstrap";
import { Input } from "../../uiKit/Input";
import { Button } from "../../uiKit/Button";
import { CollapseInformation } from "../../uiKit/CollapseInformation";
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram.svg";
import { ReactComponent as VKIcon } from "../../assets/svg/vk.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/svg/youtube.svg";
import s from "./Footer.module.scss";

const informationData = [
    { title: "О компании", url: "#" },
    { title: "Контакты", url: "#" },
    { title: "Акции", url: "#" },
    { title: "Магазины", url: "#" },
];

const internerMarketData = [
    { title: "Доставка и самовывоз", url: "#" },
    { title: "Оплата", url: "#" },
    { title: "Возврат-обмен", url: "#" },
    { title: "Новости", url: "#" },
];

const socialNetworks = [
    { icon: <InstagramIcon />, url: "https://www.instagram.com" },
    { icon: <VKIcon />, url: "https://www.vk.com" },
    { icon: <FacebookIcon />, url: "https://www.facebook.com" },
    { icon: <YoutubeIcon />, url: "https://www.youtube.com" },
];

const Footer: FC = () => {
    const [email, setEmail] = useState("");

    return (
        <footer className={s.footer}>
            <Container fluid={false}>
                <div className={s.content}>
                    <div className={s.subscribe__section}>
                        <span className={s.title}>Подпишитесь на нашу рассылку и узнайте об акции быстрее</span>
                        <div className={s.input_email__container}>
                            <Input
                                className={s.input}
                                placeholder={"Введите ваш Email"}
                                value={email}
                                onChange={setEmail}
                            />
                            <Button className={s.send_email__button}>Отправить</Button>
                        </div>
                    </div>
                    <div className={s.accordion}>
                        <CollapseInformation
                            name={"Информация"}
                            toggleContainerClassName={s.accordion_header}
                            contentClassName={s.information__section}
                            defaultOpenState={false}
                        >
                            <div className={s.information}>
                                {informationData.map((i, index) => (
                                    <a
                                        key={index}
                                        href={i.url}
                                    >
                                        {i.title}
                                    </a>
                                ))}
                            </div>
                        </CollapseInformation>
                        <CollapseInformation
                            name={"Интернет-магазин"}
                            toggleContainerClassName={s.accordion_header}
                            contentClassName={s.information__section}
                            defaultOpenState={false}
                        >
                            <div className={s.information}>
                                {internerMarketData.map((i, index) => (
                                    <a
                                        key={index}
                                        href={i.url}
                                    >
                                        {i.title}
                                    </a>
                                ))}
                            </div>
                        </CollapseInformation>
                    </div>
                    <div className={s.information__section}>
                        <div className={s.information}>
                            <span>Информация</span>
                            {informationData.map((i, index) => (
                                <a
                                    key={index}
                                    href={i.url}
                                >
                                    {i.title}
                                </a>
                            ))}
                        </div>
                        <div className={s.information}>
                            <span>Интернет-магазин</span>
                            {internerMarketData.map((i, index) => (
                                <a
                                    key={index}
                                    href={i.url}
                                >
                                    {i.title}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={s.social_networks__container}>
                        {socialNetworks.map((s, i) => (
                            <a
                                key={i}
                                href={s.url}
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                {s.icon}
                            </a>
                        ))}
                    </div>
                </div>
                <div className={s.content}>
                    <div />
                    <div className={s.information__section}>
                        <a className={s.little_information}>Договор оферты</a>
                        <a className={s.little_information}>Политика обработки персональных данных</a>
                    </div>
                    <div />
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
