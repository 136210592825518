import { FC, useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Col, Row } from "reactstrap";
import { Product } from "../../redux/reducers/shopReducer/types/product";
import { useAppDispatch } from "../../redux/hooks";
import { Slider } from "../../components/Slider";
import { ProductCard } from "../../components/ProductCard";
import s from "./Main.module.scss";
import classNames from "classnames";

const mostSale: Product[] = [
    {
        name: "ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)",
        imageUrl: "https://brp.spb.ru/upload/iblock/947/9477e39a3b93caa4ea4ea76b661eec26.jpg",
        isExist: true,
        price: 999500,
        isFavorite: false,
        isDealer: false,
        isHit: false,
        isNew: false,
        isSale: false,
        id: "02d575fc-6aa8-453b-8a36-5697732ec7de",
    },
    {
        name: "ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)",
        imageUrl: "https://brp.spb.ru/upload/iblock/947/9477e39a3b93caa4ea4ea76b661eec26.jpg",
        isExist: true,
        price: 999500,
        isFavorite: false,
        isDealer: false,
        isHit: false,
        isNew: false,
        isSale: false,
        id: "03d575fc-6aa8-453b-8a36-5697732ec7de",
    },
    {
        name: "ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)",
        imageUrl: "https://brp.spb.ru/upload/iblock/947/9477e39a3b93caa4ea4ea76b661eec26.jpg",
        isExist: true,
        price: 999500,
        isFavorite: false,
        isDealer: false,
        isHit: false,
        isNew: false,
        isSale: false,
        id: "04d575fc-6aa8-453b-8a36-5697732ec7de",
    },
    {
        name: "ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)",
        imageUrl: "https://brp.spb.ru/upload/iblock/947/9477e39a3b93caa4ea4ea76b661eec26.jpg",
        isExist: true,
        price: 999500,
        isFavorite: false,
        isDealer: false,
        isHit: false,
        isNew: false,
        isSale: false,
        id: "05d575fc-6aa8-453b-8a36-5697732ec7de",
    },
    {
        name: "ГИДРОЦИКЛ SEA-Water GTI SE 130 (2022)",
        imageUrl: "https://brp.spb.ru/upload/iblock/947/9477e39a3b93caa4ea4ea76b661eec26.jpg",
        isExist: true,
        price: 999500,
        isFavorite: false,
        isDealer: false,
        isHit: false,
        isNew: false,
        isSale: false,
        id: "06d575fc-6aa8-453b-8a36-5697732ec7de",
    },
];

const Main: FC = () => {
    const [previewCount, setPreviewCount] = useState(4);
    const dispatch = useAppDispatch();
    const toggleFavoriteHandler = useCallback((id: string) => {
        //TODO...
    }, []);
    const sliderItems = mostSale.map(p => ({
        id: p.id,
        item: (
            <ProductCard
                key={p.id}
                className={s.product}
                product={p}
                onToggleFavorite={toggleFavoriteHandler}
            />
        ),
    }));

    useEffect(() => {
        function resizeHandler() {
            const width = window.innerWidth;
            if (width > 1399) {
                setPreviewCount(4);
            } else if (width >= 1020 && width <= 1399) {
                setPreviewCount(3);
            } else if (width >= 767 && width <= 1020) {
                setPreviewCount(2);
            } else setPreviewCount(1);
        }
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    return (
        <main>
            <Breadcrumbs />
            <Outlet />
            <Row>
                <Col xl={12}>
                    <h3 className={s.most_sale__title}>Часто покупают</h3>
                </Col>
            </Row>
            <Row className={s.most_sale__container}>
                <Slider
                    prevControlClassName={classNames(s.control, s.prev)}
                    nextControlClassName={classNames(s.control, s.next)}
                    indicatorsClassName={s.indicators}
                    items={sliderItems}
                    itemsPreviewCount={previewCount}
                />
            </Row>
        </main>
    );
};

export default Main;
